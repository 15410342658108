import * as React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { GlobeAltIcon } from "@heroicons/react/outline";

import { Layout } from "../components";
import { MetaData } from "../components/common/meta";
import { TwitterSvg } from "../components/svg";

/**
 * Authors page (/topics)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */

const Topics = ({ data, location }) => {
  const authors = data.allGhostAuthor.edges;
  const metaTitle = `Our Authors`;
  return (
    <>
      <MetaData title={metaTitle} data={data} location={location} />
      <Layout title={metaTitle}>
        <div className="max-w-7xl mx-auto px-4 sm:px-8 mt-20 mb-32 space-y-8">
          <h1 className="uppercase">Authors</h1>
          <div className="grid lg:grid-cols-2 gap-x-24 gap-y-16">
            {authors.map(({ node }, i) => (
              <div key={`author-p-${i}`}>
                {node.profile_image && (
                  <figure className="mb-6">
                    <img
                      className="inline-block h-40 w-40 rounded-full"
                      src={node.profile_image}
                      alt={node.name}
                    />
                  </figure>
                )}
                <div className="flex justify-between items-center mb-3">
                  <h2 className="text-lg leading-5 font-bold text-gray-900">
                    {node.name}
                  </h2>
                  <div className="flex space-x-3">
                    {node.twitter && (
                      <Link
                        to={`https://twitter.com/${node.twitter.replace(
                          /^@/,
                          ``
                        )}/`}
                        target="_blank"
                        className="w-6 h-6 rounded-full bg-black flex items-center justify-center"
                      >
                        <span className="sr-only">
                          Follow {node.name} on Twitter
                        </span>
                        <TwitterSvg className="h-3 w-3 text-white" />
                      </Link>
                    )}
                    {node.website && (
                      <Link
                        to={node.website}
                        target="_blank"
                        className="w-6 h-6 rounded-full bg-black flex items-center justify-center"
                      >
                        <span className="sr-only">
                          Checkout {node.name}'s website
                        </span>
                        <GlobeAltIcon className="h-3 w-3 text-white" />
                      </Link>
                    )}
                  </div>
                </div>
                {node.bio && (
                  <p className="text-sm text-thin mb-3">{node.bio}</p>
                )}
                <Link className="link" to={`/authors/${node.slug}`}>
                  See articles by {node.name.split(" ")[0]}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </>
  );
};

Topics.propTypes = {
  data: PropTypes.shape({
    allGhostAuthor: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query AuthorsPageQuery {
    allGhostAuthor(sort: { order: ASC, fields: name }) {
      edges {
        node {
          ...GhostAuthorFields
        }
      }
    }
  }
`;

export default Topics;
